<template>
  <section id="Overview">
    <div
      class="overview__container"
      v-for="(stats, index) in overviewData"
      :key="'overview' + index"
    >
      <div class="overview__content">
        <img
          class="overview__content-image"
          v-if="stats.sectionImage"
          :src="require('@/assets/images/' + stats.sectionImage)"
          alt="section image"
        />
        <h1 class="customTitle" v-else>{{ stats.sectionTitle }}</h1>
        <p>{{ stats.paragraph }}</p>
        <div class="divider"></div>
        <div class="overview__content__bottom">
          <div class="description" v-if="stats.area">
            <img
              class="floorplan"
              src="@/assets/icons/Overview/Floorplan-icon.svg"
            />
            <div class="description__right">
              <div class="description__right-top">{{ stats.area }}</div>
              <div class="description__right-bottom">square feet</div>
            </div>
          </div>
          <div class="description" v-if="stats.reception">
            <img src="@/assets/icons/Overview/person-icon.svg" />
            <div class="description__right">
              <div class="description__right-top">{{ stats.reception }}</div>
              <div class="description__right-bottom">reception capacity</div>
            </div>
          </div>
          <div class="description" v-if="stats.banquet">
            <img
              class="banquet"
              src="@/assets/icons/Overview/banquet-icon.svg"
            />
            <div class="description__right">
              <div class="description__right-top">{{ stats.banquet }}</div>
              <div class="description__right-bottom">banquet capacity</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Overview",
  props: {
    overviewData: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
#Overview {
  width: 100vw;
  height: 100vh;
  position: relative;
  // padding-left: 268px;
  @media (max-width: 993px) {
    height: auto;
  }
  .overview {
    &__container {
      background-image: linear-gradient(
        90deg,
        rgba(0, 0, 0, 0.84) 48%,
        rgba(0, 0, 0, 0) 89%
      );
      width: 100%;
      height: 100%;
      @media (max-width: 993px) {
        height: auto;
        background-image: linear-gradient(
          270deg,
          rgba(0, 0, 0, 0.83) 20%,
          rgba(0, 0, 0, 0.92) 82%
        );
        padding-bottom: 50px;
      }
    }
    &__content {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      height: 100%;
      width: 600px;
      padding-top: calc(74px + 125px);
      margin-left: calc(268px + 77.6px);
      @media (max-width: 993px) {
        margin-left: 100px;
        width: 80%;
        max-width: 600px;
      }
      @media (max-width: $md) {
        margin-left: 50px;
      }
      @media (max-width: $sm) {
        margin-left: 20px;
      }
      &-image {
        max-height: 204px;
      }
      h1 {
        display: flex;
        align-items: flex-end;
        height: 200px;
        font-family: "ArtegraSans-Bold";
        color: #ffffff;
        letter-spacing: 1.38px;
        text-align: left;
        font-size: 75px;
        text-transform: uppercase;
        line-height: 62px;

        &.customTitle {
          width: 150px;
          @media (max-width: 993px) {
            font-size: 75px;
            line-height: 85px;
          }
          @media (max-width: 768px) {
            font-size: 30px;
            line-height: 30px;
            width: auto;
            height: auto;
          }
        }
      }
      p {
        font-family: "ArtegraSans-Light";
        font-size: 15px;
        color: #ffffff;
        letter-spacing: 1.38px;
        line-height: 22px;
        padding: 32px 0 21px 0;
      }
      .divider {
        background: #ffffff;
        box-shadow: 0 0 8px 2px rgba(255, 255, 255, 0.8);
        height: 2px;
        width: 100%;
      }
      &__bottom {
        display: flex;
        flex-direction: row;
        // justify-content: space-between;
        justify-content: flex-start;
        align-items: center;
        width: calc(100% + 17px);
        padding-top: 20px;
        margin-left: -17px;
        @media (max-width: 993px) {
          flex-direction: column;
          align-items: flex-start;
          width: 100%;
          margin: 0;
        }
        img {
          margin-right: 12px;
        }
        .floorplan {
          @media (max-width: 993px) {
            margin-left: -16px;
            margin-right: 10px;
          }
        }
        .banquet {
          @media (max-width: 993px) {
            margin-left: -6px;
            width: 45px;
            margin-right: 8px;
          }
        }
        .description {
          flex: 1;
          display: flex;
          flex-direction: row;
          min-width: 30%;
          max-width: 36%;
          @media (max-width: 993px) {
            max-width: 100%;
          }

          &__right {
            color: #ffffff;
            text-transform: uppercase;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            &-top {
              font-family: "ArtegraSans-Light";
              font-size: 31px;
              letter-spacing: 1.94px;
            }
            &-bottom {
              font-family: "ArtegraSans-Bold";
              font-size: 12px;
              letter-spacing: 0.8px;
            }
          }
        }
      }
    }
  }
}
</style>